import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import NaHeader from "components/hero/NaHeader.js";
import AboutNa from "components/features/aboutna.js";
import Meetings from "components/pricing/meetings.js";
import FAQ from "components/faqs/Faqs.js";
import Footer from "components/footers/NaFooter.js";
import MeetingAddresses from "pages/MeetingAddresses";




export default () => {

  return (
    <AnimationRevealPage disabled={true}>
      <NaHeader />
      <AboutNa />
      <Meetings />
      < MeetingAddresses />
      <FAQ />
      <Footer />
    </AnimationRevealPage>
  );
}
