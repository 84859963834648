import React, { useState } from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Meeting Addresses",
  posts = [

    getGratitudeGroupDetails(),
    getNewPathGroupDetails(),
    getBlessedGroupDetails(),
    getGraceGroupDetails(),
    getSereneGroupDetails(),
    getChoosenOneGroupDetails(),
    getUnityGroupDetails(),
    getEmailPost(),
    getWhatsappPost(),
  ]
}) => {
  const [visible, setVisible] = useState(8);
  const onLoadMoreClick = () => {
    setVisible(v => v + 8);
  };
  return (

      <PrimaryBackgroundContainer><Container id="meeting">
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url}>
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      </PrimaryBackgroundContainer>

  );
};

const getEmailPost = () => ({
  imageSrc:
    "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Email Us",
  date: "Email on nachdhelpline@gmail.com",
  title: "Get a reply within 24 hours",
  url:"mailto:nachdhelpline@gmail.com"
 /*  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://reddit.com" */
});
const getWhatsappPost = () => ({
  imageSrc:
    "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: "Whatsapp Us",
  date: "Whatsapp us on 9217706222",
  title: "Connect with us via Whatsapp",
  url:"https://api.WhatsApp.com/send?phone=919217706222"
 /*  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://reddit.com" */
});
const getGratitudeGroupDetails = () => ({
    category: "Gratitude Group",
  title: "Lala Lajpat Rai Bhawan, Sector 15 Chandigarh",
  date: "Monday(Open): 6:30 pm to 8pm \n, Friday(Closed):  6:30 pm to 8pm",
  url:"https://goo.gl/maps/miuXqJuXevwrH8q7A"
});
const getNewPathGroupDetails = () => ({
  category: "New Path Group",
title: "Cathedral Church, Sector 19, Chandigarh",
date: "Thursday(Close): 6:30 pm to 8pm \n, Sunday(Open):  6:30 pm to 8pm",
url:"https://goo.gl/maps/AWKituZKPoVXa5ps5"
});
const getBlessedGroupDetails = () => ({
  category: "Blessed Group",
title: "Family Planning Office, Phase 3a, Mohali",
date: "Wednesday(Open): 6:30 pm to 8pm",
url:"https://goo.gl/maps/hKntKjcx76pBBzzE6"
});
const getChoosenOneGroupDetails = () => ({
  category: "Choosen Ones Group",
title: "Saint Stephens School, Sector 45b, Chandigarh",
date: "Tuesday(Close): 6:30 pm to 8pm",
url:"https://goo.gl/maps/jFMgM3eG4WgTWNnZ6"
});
const getGraceGroupDetails = () => ({
  category: "Grace Group",
title: "Arya Samaj Mandir, Sector 9, Panchkula",
url:"https://maps.app.goo.gl/hpJWv8sE9GVj8vVs7",
date: "Wednesday(Close),Saturday(Open): 6:30 pm to 8pm",
});
const getSereneGroupDetails = () => ({
  category: "Serene Group",
title: "Saint Stephens School, Sector 45b, Chandigarh",
url:"https://goo.gl/maps/jFMgM3eG4WgTWNnZ6",
date: "Saturday(Open): 6:30 pm to 8pm",
});
const getUnityGroupDetails = () => ({
  category: "Unity Group",
title: "Online on Zoom App",
date: "EveryDay 6:30pm to 8:00 pm(Evening)",
url:"https://zoom.us/j/378861892?pwd=bnFDYlpUTHVKb2NhY0dNcXI3M3A2QT09"
});


